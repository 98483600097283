<template>
  <div class="allmsgclass">
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
    <div
      @click="showWechat = !showWechat"
      v-show="showWechat"
      class="wechatclass"
    >
      <div></div>
      <div>
        <img src="../assets/wechat_tip.png" alt="" />
        <div>点击右上角分享</div>
      </div>
    </div>
    <van-popup v-model="show">
      <div class="topmsg">
        <textarea
          @click="copymsg"
          readonly="readonly"
          v-model="linkMsg"
          name=""
          id="textid"
          rows="4"
          cols="20"
        ></textarea>
        <div @click="copymsg">点击复制链接</div>
      </div>
    </van-popup>
    <van-popup v-model="showqrcode">
      <qrcode
        :url="qrcodeMsg"
        :wid="232"
        :hei="232"
        :imgwid="40"
        :imghei="36"
      ></qrcode>
      <div class="savecodeclass">长按保存二维码</div>
    </van-popup>
    <sharepage ref="sharitid"></sharepage>
  </div>
</template>
<script>
import qrcode from "vue_qrcodes";
import sharepage from "../views/sharepage/index.vue";
import { Toast } from "vant";
export default {
  components: {
    sharepage,
    qrcode,
  },
  data() {
    return {
      allmsg: {},
      showShare: false, //分享页面
      show: false,
      showqrcode: false,
      options: [
        // { name: "微信", icon: "wechat" },
        // { name: "微博", icon: "weibo" },
        // { name: "复制链接", icon: "link" },
        // { name: "分享海报", icon: "poster" },
        // { name: "二维码", icon: "qrcode" },
      ],
      linkMsg: "",
      qrcodeMsg: "",
      showWechat: false,
      subLogId:""
    };
  },
  methods: {
    init(msg) {
      this.options = msg.options;
      this.showShare = true;
      this.allmsg = msg;
    },
    //选择的分享方式
    onSelect(e) {
      switch (e.icon) {
        case "wechat":
          this.wechatMeth();
          break;
        case "weibo":
          this.weiboMeth();
          break;
        case "link":
          this.linkMeth();
          break;
        case "poster":
          this.posterMeth();
          break;
        case "qrcode":
          this.qrcodeMeth();
          break;
      }
    },
    //存储数据（临时已填写的数据）
    //writeMsg:可选参数
    saveMsg() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "请稍后...",
      });
      return new Promise((pro, rej) => {
        if (!this.allmsg.writeMsg) {
          Toast.clear();
          pro("");
          return;
        } else {
          this.$http
            .post("/firm/v1/Common/addSubLog", {
              reqType: "resume",
              theme: this.allmsg.writeMsg.theme,
              sex: this.allmsg.writeMsg.sex,
              age: this.allmsg.writeMsg.age,
              jop: this.allmsg.writeMsg.jop,
              education_background: this.allmsg.writeMsg.education_background,
              area: this.allmsg.writeMsg.area,
              industry: this.allmsg.writeMsg.industry,
              salary: this.allmsg.writeMsg.salary,
              years_working: this.allmsg.writeMsg.years_working,
              politics: this.allmsg.writeMsg.politics,
              desc: this.allmsg.writeMsg.desc,
              dep_build: this.allmsg.writeMsg.dep_build,
              dep_culture: this.allmsg.writeMsg.dep_culture,
            })
            .then((res) => {
              Toast.clear();
              this.subLogId = JSON.parse(res.data).data.subLogId;
              pro();
            });
        }
      });
    },
    //微信分享
    async wechatMeth() {
      if (this.allmsg.wechatMsg) {
        await this.saveMsg();
        this.allmsg.wechatMsg.subLogId = this.subLogId;
        let obj = JSON.stringify(this.allmsg.wechatMsg);
        localStorage.setItem("shareobj", obj);
        this.$refs.sharitid.getmsg();
        this.showShare = false;
        this.showWechat = true;
      } else {
        this.$toast("失败,缺少参数");
      }
    },
    //微博分享
    weiboMeth() {
      this.$toast("暂未开放该功能");
    },
    //链接分享
    async linkMeth() {
      if (this.allmsg.linkMsg) {
        await this.saveMsg();
        this.show = true;
        this.showShare = false;
        this.allmsg.linkMsg+="&subLogId="+btoa(this.subLogId);
        this.linkMsg = this.allmsg.linkMsg;
      } else {
        this.$toast("失败，缺少参数");
      }
    },
    //海报分享
    posterMeth() {},
    //二维码分享
    async qrcodeMeth() {
      if (this.allmsg.qrcodeMsg) {
        await this.saveMsg();
        this.showqrcode = true;
        this.showShare = false;
        this.allmsg.qrcodeMsg+="&subLogId="+btoa(this.subLogId);
        this.qrcodeMsg = this.allmsg.qrcodeMsg;
      } else {
        this.$toast("失败，缺少参数");
      }
    },
    copymsg() {
      let textareamsg = document.getElementById("textid");
      textareamsg.select();
      document.execCommand("copy");
      window.getSelection().empty();
      this.$toast("复制成功");
      textareamsg = null;
    },
  },
};
</script>
<style scoped>
.wechatclass {
  position: fixed;
  top: 0;
  z-index: 3000;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.4rem;
  color: white;
  font-weight: bold;
}
.wechatclass > div {
  text-align: center;
  margin-right: 0.3rem;
}
.wechatclass img {
  width: 2rem;
}
.savecodeclass {
  text-align: center;
  font-weight: bold;
  font-size: 0.4rem;
  margin-top: 0.3rem;
}
.topmsg {
  font-size: 0.3rem;
  font-weight: 400;
  padding-top: 0.1rem;
}
.topmsg > :first-child {
  font-size: 0.28rem;
}
.topmsg {
  margin: 0.3rem 0;
  text-align: center;
  width: 100%;
  border: none;
}
.allmsgclass >>> .van-popup--center {
  border-radius: 0.3rem;
  padding: 0.3rem;
  width: 65%;
  text-align: center;
}
textarea {
  border: none;
  text-align: center;
  margin-bottom: 0.3rem;
  font-size: 0.4rem;
}
</style>