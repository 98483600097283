<!--
 * @Author: your name
 * @Date: 2021-01-06 14:05:45
 * @LastEditTime: 2021-06-01 19:12:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\sharepage\index.vue
-->
<!-- 
  例：
      let obj = JSON.stringify({
        sharetitle: "查看报告",
        sharedesc: "点击查看报告",
        isshare:"1",
        shareurl:"reportview",
        z_id:this.$route.query.z_id,
        r_id:this.$route.query.r_id
      });
      localStorage.setItem("shareobj", obj);
      window.location = "/sharepage";
-->

<template>
  <div>
    <!-- <navigator :msg="msg"></navigator> -->
    <shareit ref="sharitid"></shareit>
  </div>
</template>
<script>
import shareit from "../../components/shareit/index";
import base from "../../../util/base";
import navigator from "../../views/navigation/index";
export default {
  components: {
    shareit,
    navigator,
  },
  data() {
    return {
      shareobj: "",
      msg: "分享",
      url2:""
    };
  },
  mounted() {
    this.url2 = sessionStorage.getItem("url");
    this.getmsg();
  },
  methods: {
    getmsg() {
      setTimeout(() => {
        if (this.isornosharemeth()) {
          this.shareobj = JSON.parse(localStorage.getItem("shareobj"));
          this.clickshare();
        } else {
          this.shareobj = JSON.parse(localStorage.getItem("shareobj"));
          this.clickshare();
        }
      }, 0);
    },
    /**
     * 是否是根据分享链接点击进入
     * isshare （1：是 0/""/undefined：否） if true 必传shareurl
     * return true/false
     */
    isornosharemeth() {
      if (this.$route.query.isshare && this.$route.query.isshare == 1) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 需要分享的链接地址及参数
     * return link(调起分享链接地址)
     */
    getlinkandmsg() {
      // let theurl2 = base.url + "/" + this.shareobj.shareurl + "?";
      let theurl2 = base.url + this.url2 + "?";

      Object.keys(this.shareobj).forEach((res) => {
        if (res != "sharetitle" && res != "sharedesc") {
          theurl2 += res + "=" + this.shareobj[res] + "&";
        }
      });
      theurl2 =
        theurl2 +
        "z_id=" +
        localStorage.getItem("z_id") +
        "&r_id=" +
        localStorage.getItem("r_id") +
        "&urlsharees=" +
        this.$route.path;
      console.log(theurl2);
      return theurl2;
    },
    /**
     * shareurl:"分享进入后跳转的链接"
     * isshare:"是否是点击分享链接进入 （1：true or false）"
     * sharetitle:"分享标题"
     * sharedesc:"分享描述"
     */
    clickshare() {
      this.$refs.sharitid.getwxshare({
        title: this.shareobj.sharetitle, // 分享标题
        desc: this.shareobj.sharedesc, // 分享描述
        link: this.getlinkandmsg(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: this.shareobj.mylinkurl?this.shareobj.mylinkurl:base.linkurl, // 分享图标
        // imgUrl: base.linkurl, // 分享图标
      });
    },
  },
};
</script>
<style scoped>
</style>