export default [
    "面议",
    "5000-10000(元)",
    "10000-15000(元)",
    "15000-20000(元)",
    "20000-25000(元)",
    "25000-30000(元)",
    "30000-35000(元)",
    "35000-40000(元)",
    "40000-50000(元)",
    "50000-60000(元)",
    "60000(元)以上",
]