<!--
 * @Author: your name
 * @Date: 2020-12-01 13:21:03
 * @LastEditTime: 2021-04-09 16:26:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\navigation\index.vue
  :class="leftcolor?'rigcoclass':''"
-->
<template>
  <div class="">
    <van-nav-bar
      :style="'background:' + background"
      :title="msg"
      :left-arrow="left == 'noleft' ? false : true"
      @click-right="onClickRight"
      @click-left="onClickLeft"
    >
      <template v-if="right == true ? '首页' : ''" #right>
        <img class="iconclass" src="../../assets/homeicon.png" />
      </template>
    </van-nav-bar>
  </div>
</template>
<script>
export default {
  props: {
    background: String,
    msg: String,
    left: String,
    right: Boolean,
    classes: String,
  },
  data() {
    return {
      aa: true,
    };
  },

  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped>
.van-nav-bar >>> .van-icon {
  color: #000000;
  font-size: 0.36rem;
}
.rigcoclass {
  color: white !important;
}
.naclass {
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  top: 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #eeeeee;
}
.naclass div {
  width: 100%;
  text-align: center;
  padding: 0.3rem 0;
}
.naclass div span:first-child {
  color: #141f33;
  float: left;
  margin-left: 0.3rem;
  font-size: 20px;
  line-height: 0.55rem;
  font-weight: normal;
}
.naclass div span:nth-child(2) {
  font-size: 15px;
}
.iconclass {
  width: 0.4rem;
}
</style>