<!--
 * @Author: your name
 * @Date: 2021-04-21 15:16:55
 * @LastEditTime: 2021-06-01 16:59:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\dingyuesetup\index.vue
-->
<template>
  <div id="dingyue" class="allmsgclass">
    <navigation
      :stylemsg="'background-color: #f6f6f7;'"
      :msg="header ? '完善岗位信息' : '订阅内容设置'"
    >
      <div v-if="!header" @click="showMenuMeth" class="setding">
        <van-icon size=".4rem" name="share-o" />
        <div>邀请协助</div>
      </div>
    </navigation>
    <div v-if="header" class="topmsg">
      <img src="../../assets/dingyuesetup_back.png" alt="" />
      <div>本信息需要您的完善及确认</div>
      <div>该链接24小时内有效，请尽快填写完成</div>
    </div>
    <div class="gwclass">
      <div><span style="color: #ff6d20">*</span>岗位名称</div>
      <input
        v-model="theme"
        placeholder="请输入订阅的岗位名称"
        class="inputclass"
        type="text"
      />
    </div>
    <div class="thecenterclass">
      <div @click="showshow = true" class="centerclass">
        <div><span style="color: #ff6d20">*</span>选择岗位</div>
        <div v-if="jop">{{ jop }}</div>
        <van-icon v-else size=".3rem" color="#C0C4CC" name="arrow" />
      </div>
      <div @click="$refs.addressid.addressmeth('2', '')" class="centerclass">
        <div><span style="color: #ff6d20">*</span>工作地点</div>
        <div v-if="area">{{ area }}</div>
        <van-icon v-else size=".3rem" color="#C0C4CC" name="arrow" />
      </div>
      <div @click="showback = true" class="centerclass">
        <div><span style="color: #ff6d20">*</span>最低学历</div>
        <div v-if="education_background">{{ education_background }}</div>
        <van-icon v-else size=".3rem" color="#C0C4CC" name="arrow" />
      </div>
      <div @click="showyear = true" class="centerclass">
        <div>工作年限</div>
        <div v-if="years_working">{{ years_working }}</div>
        <van-icon v-else size=".3rem" color="#C0C4CC" name="arrow" />
      </div>
      <div @click="showPicker = true" class="centerclass">
        <div><span style="color: #ff6d20">*</span>薪资范围</div>
        <div v-if="salary">{{ salary }}</div>
        <van-icon v-else size=".3rem" color="#C0C4CC" name="arrow" />
      </div>
    </div>
    <div class="areaclass">
      <div><span style="color: #ff6d20">*</span>职位描述/职责</div>
      <textarea
        placeholder="请输入职位描述/职责 （请勿填写QQ、微信、电话等联系方式及特殊字符，性别歧视词，违反劳动法，民法典等相关内容，否则有可能导致您的账号被封停。）"
        name=""
        id=""
        cols="30"
        rows="4"
        v-model="desc"
        maxlength="1600"
      ></textarea>
      <div class="fontnumclass">
        <div></div>
        <div :style="String(desc).length > 1600 ? 'color:red' : ''">
          {{ String(desc).length }}/1600
        </div>
      </div>
    </div>
    <div class="gwclass">
      <div>部门文化</div>
      <!-- <input
        v-model="dep_culture"
        placeholder="请输入该岗位部门的文化"
        class="inputclass"
        type="text"
        maxlength="300"
      /> -->
      <textarea
        placeholder="请输入该岗位部门的文化"
        name=""
        id=""
        cols="30"
        rows="2"
        maxlength="300"
        style="border:none"
        v-model="dep_culture"
      ></textarea>
      <div
        :style="String(dep_culture).length > 300 ? 'color:red' : ''"
        class="fontnumthe"
      >
        {{ String(dep_culture).length }}/300
      </div>
    </div>
    <div class="gwclass">
      <div>部门建设</div>
      <textarea
        placeholder="请输入该岗位部门福利或相关政策"
        name=""
        id=""
        cols="30"
        rows="2"
        maxlength="300"
        style="border:none"
        v-model="dep_build"
      ></textarea>
      <div
        :style="String(dep_build).length > 300 ? 'color:red' : ''"
        class="fontnumthe"
      >
        {{ String(dep_build).length }}/300
      </div>
    </div>
    <div @click="savedingyue" class="bcdyclass">
      {{ header ? "保存订阅" : "保存订阅并发布" }}
    </div>
    <div v-if="header" class="luoboclass">
      <img src="../../assets/luoboico.png" alt="" />
    </div>
    <div style="height: 0.5rem"></div>

    <!--薪资范围-->
    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        title="薪资范围"
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
    <!--工作年限-->
    <!-- <van-popup v-model="showyear" round position="bottom">
      <van-picker
        title="工作年限"
        show-toolbar
        :columns="years_workingArray"
        @cancel="showyear = false"
        @confirm="onyear"
      />
    </van-popup> -->
    <van-popup v-model="showyear" round position="bottom">
      <van-picker
        show-toolbar
        title="工作年限区间"
        @cancel="showyear = false"
        @confirm="confirmyearwork"
        :columns="columns22"
      />
    </van-popup>
    <!--学历选择-->
    <van-popup v-model="showback" round position="bottom">
      <van-picker
        title="学历选择"
        show-toolbar
        :columns="columns2"
        @cancel="showback = false"
        @confirm="onback"
      />
    </van-popup>
    <van-popup
      v-model="showshow"
      round
      position="bottom"
      :style="{ height: '100%' }"
      get-container="#dingyue"
    >
      <postselect></postselect>
    </van-popup>
    <addresses
      :msg="2"
      @addressreturn="addressreturn"
      ref="addressid"
    ></addresses>
    <sharemenu ref="sharemenuref"></sharemenu>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import station from "../../components/station/index";
import eventbus from "../../../util/eventbus.js";
import postselect from "../../components/postselect/index.vue";
import addresses from "../../components/address";
import education from "../../../util/education";
import expectedSalary from "../../../util/expectedSalary";
import workingYears from "../../../util/workingYears";
import workingYears2 from "../../../util/workingYears2";
import sharemenu from "../../components/sharemenu.vue";
import base from "../../../util/base";
import { Dialog } from "vant";
export default {
  components: {
    station,
    postselect,
    addresses,
    sharemenu,
  },
  data() {
    return {
      showPicker: false,
      showhangye: false,
      showaddress: false,
      showyear: false,
      showback: false,
      showshow: false,

      industry: "", //意向行业
      salary: "", //期望薪资
      area: "", //求职区域
      years_working: "", //工作年限
      education_background: "", //学历选择
      jop: "", //训责岗位
      id: "", //订阅id
      theme: "", //订阅主题

      isupdate: false, //是否时修改订阅进入

      columns22: [
        // 第一列
        {
          values: workingYears2,
          defaultIndex: 0,
        },
        // 第二列
        {
          values: workingYears2,
          defaultIndex: 1,
        },
      ],

      areaList: areaList, //地区数组
      addressname: "", //地区名字
      indexhang: {},
      areazan: "",
      columns: expectedSalary,
      columns2: education,
      years_workingArray: workingYears,
      msges: "",
      header: "", //自定义头（分享）
      desc: "", //职位描述/职责
      dep_culture: "", //部门文化
      dep_build: "", //部门建设
    };
  },
  created() {
    this.msges = JSON.parse(sessionStorage.getItem("sharemsg"));
    // 微信分享
    if (this.msges && this.msges.isshare && this.msges.isshare == "1") {
      this.header = {
        accessToken: this.msges.accessToken,
        token: this.msges.token,
        userId: this.msges.userId,
      };
      //查询已有数据
      this.slectSaveMsg(this.msges.subLogId);
    } else if (this.$route.query.linkShare) {
      //链接分享或者二维码分享
      this.header = {
        accessToken: atob(this.$route.query.accessToken),
        token: atob(this.$route.query.token),
        userId: atob(this.$route.query.userId),
      };
      //查询已有数据
      this.slectSaveMsg(atob(this.$route.query.subLogId));
    } else {
    }
    eventbus.$on("config", (e) => {
      this.jop = e;
      this.showshow = false;
    });
    eventbus.$on("cancel", (e) => {
      this.showshow = false;
    });
    if (this.$route.query.updatemsg) {
      //修改订阅进入
      this.isupdate = true;
    }
    if (this.$route.query.msg) {
      let msg = JSON.parse(this.$route.query.msg);
      this.industry = msg.industry; //意向行业
      this.salary = msg.salary; //期望薪资
      this.area = msg.area; //求职区域
      this.years_working = this.conversion(msg.years_working); //工作年限
      this.education_background = msg.education_background; //学历选择
      this.jop = msg.jop; //订阅主题
      this.theme = msg.theme;
      if (msg.id) {
        this.id = msg.id;
      }
      this.desc = msg.desc;
      this.dep_culture = msg.dep_culture;
      this.dep_build = msg.dep_build;
    }
  },
  watch: {
    showhangye(oldmsg) {
      this.industry = "";
      if (oldmsg == false) {
        Object.keys(this.indexhang).forEach((e) => {
          this.industry += this.indexhang[e] + "";
        });
      } else {
      }
    },
    showaddress(oldmsg) {
      if (oldmsg == false && this.areazan) {
        this.area = this.areazan[0].name + "/" + this.areazan[1].name;
      }
    },
  },
  methods: {
    //分享数据进入，查询数据
    slectSaveMsg(subLogId) {
      this.$http
        .post(
          "/firm/v1/Common/getSubInfo",
          {
            reqType: "resume",
            subLogId: subLogId,
          },
          this.header
        )
        .then((res) => {
          
          let themsg = JSON.parse(res.data).data.subLogInfo;
          //日期24小时有效
          if (!(new Date() - new Date(String(themsg.add_time).replace(/-/g, "/")) < 1000 * 60 * 60 * 24)) {
            Dialog.alert({
              title: "提醒",
              message: "链接24小时有效",
            }).then(() => {
              this.$router.push("/home");
            });
            return;
          }
          this.industry = themsg.industry; //意向行业
          this.salary = themsg.salary; //期望薪资
          this.area = themsg.area; //求职区域
          this.years_working = this.conversion(themsg.years_working); //工作年限
          this.education_background = themsg.education_background; //学历选择
          this.jop = themsg.jop; //选择岗位
          this.theme = themsg.theme; //订阅主题
          this.desc = themsg.desc;
          this.dep_culture = themsg.dep_culture;
          this.dep_build = themsg.dep_build;
        })
        .catch((err) => {
          this.$toast(err);
        });
    },
    showMenuMeth() {
      this.$refs.sharemenuref.init({
        options: [
          { name: "微信", icon: "wechat" },
          { name: "复制链接", icon: "link" },
          { name: "二维码", icon: "qrcode" },
        ],
        wechatMsg: {
          sharetitle: "完善岗位信息",
          sharedesc: "本信息需要您的完善及确认",
          isshare: "1",
          accessToken: localStorage.getItem("accessToken"),
          token: localStorage.getItem("token"),
          userId: localStorage.getItem("userId"),
        },
        linkMsg:
          base.url +
          this.$route.path +
          "?accessToken=" +
          btoa(localStorage.getItem("accessToken")) +
          "&token=" +
          btoa(localStorage.getItem("token")) +
          "&userId=" +
          btoa(localStorage.getItem("userId")) +
          "&linkShare="+btoa(1),
        qrcodeMsg:
          base.url +
          this.$route.path +
          "?accessToken=" +
          btoa(localStorage.getItem("accessToken")) +
          "&token=" +
          btoa(localStorage.getItem("token")) +
          "&userId=" +
          btoa(localStorage.getItem("userId")) +
          "&linkShare="+btoa(1),
        writeMsg: {
          industry: this.industry, //意向行业
          salary: this.salary, //期望薪资
          area: this.area, //求职区域
          years_working: this.conversion(this.years_working), //工作年限
          education_background: this.education_background, //学历选择
          jop: this.jop, //选择岗位
          theme: this.theme, //订阅主题
          desc: this.desc,
          dep_culture: this.dep_culture,
          dep_build: this.dep_build,
        },
      });
    },
    //转换值
    conversion(msg) {
      try {
        let m1 = String(msg).split("-")[0];
        let m2 = String(msg).split("-")[1];
        if (m1 == undefined || m2 == undefined) {
          return "";
        }
        if (m1 != "应届" && Number(m1) < 10) {
          m1 = m1.replace(/0/g, "应届");
        }
        if (m2 != "应届" && Number(m2) < 10) {
          m2 = m2.replace(/0/g, "应届");
        }
        return m1 + "-" + m2;
      } catch (e) {
        return "";
      }
    },

    //求职区域返回的值
    addressreturn(msg, index) {
      this.area = msg[0].name + "/" + msg[1].name;
    },
    //选择工作年限
    confirmyearwork(msg) {
      if ((msg[0] + "").replace("应届", 0) > (msg[1] + "").replace("应届", 0)) {
        this.$toast("起始时间不能大于结束时间");
        return;
      }
      this.showyear = false;
      this.years_working = msg.join("-");
    },
    //保存订阅
    savedingyue() {
      if (!this.theme) {
        this.$toast("请填写订阅主题");
        return;
      }
      if (!this.jop) {
        this.$toast("请选择岗位");
        return;
      }
      if (!this.area) {
        this.$toast("请选择工作地点");
        return;
      }
      if (!this.education_background) {
        this.$toast("请选择学历");
        return;
      }
      if (!this.salary) {
        this.$toast("请选择薪资范围");
        return;
      }
      if (!this.desc) {
        this.$toast("请填写职位描述/职责");
        return;
      }
      if (this.$route.query.updatemsg) {
        //修改订阅
        this.$http
          .post("/firm/v1/Resume/subscribe", {
            reqType: "resume",
            id: this.id,
            industry: this.industry, //意向行业
            salary: this.salary, //期望薪资
            area: this.area, //求职区域
            years_working: this.conversion(this.years_working), //工作年限
            education_background: this.education_background, //学历选择
            jop: this.jop, //选择岗位
            theme: this.theme, //订阅主题
            desc: this.desc,
            dep_culture: this.dep_culture,
            dep_build: this.dep_build,
          })
          .then((res) => {
            if (JSON.parse(res.data).msg == "成功") {
              this.$router.go(-2);
            } else {
              this.$toast(JSON.parse(res.data).msg);
            }
          })
          .catch(() => {
            this.$toast("出错代码：10000");
          });
      } else {
        this.$http
          .post(
            "/firm/v1/Resume/subscribe",
            {
              reqType: "resume",
              industry: this.industry, //意向行业
              salary: this.salary, //期望薪资
              area: this.area, //求职区域
              years_working: this.conversion(this.years_working), //工作年限
              education_background: this.education_background, //学历选择
              jop: this.jop, //选择岗位
              theme: this.theme, // 订阅主题
              desc: this.desc,
              dep_culture: this.dep_culture,
              dep_build: this.dep_build,
            },
            this.header
          )
          .then((res) => {
            if (this.header) {
              Dialog.alert({
                title: "提醒",
                message: "感谢您完善岗位信息,点击确认返回首页。",
              }).then(() => {
                this.$router.push("/home");
              });
              return;
            }
            if (JSON.parse(res.data).msg == "成功") {
              this.$router.go(-1);
            } else {
              this.$toast(JSON.parse(res.data).msg);
            }
          })
          .catch(() => {
            this.$toast("出错代码：10001");
          });
      }
    },
    selehang(msg, index) {
      this.indexhang = [];
      if (this.indexhang[index] == msg) {
        this.$set(this.indexhang, index, "");
      } else {
        this.$set(this.indexhang, index, msg);
      }
    },
    //选择薪资
    onConfirm(value) {
      this.showPicker = false;
      this.salary = value;
    },
    //选择地址
    onaddress(msg) {
      this.showaddress = false;
      this.areazan = msg;
    },
    //工作年限
    onyear(msg) {
      this.showyear = false;
      this.years_working = msg;
    },
    //学历选择
    onback(msg) {
      this.showback = false;
      this.education_background = msg;
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.luoboclass {
  text-align: center;
  margin-top: 0.44rem;
}
.luoboclass img {
  height: 0.26rem;
}
.topmsg {
  text-align: center;
}
.topmsg > img {
  width: 2.8rem;
  margin-top: 0.56rem;
}
.topmsg > :nth-child(2) {
  font-size: 0.32rem;
  font-weight: 600;
  color: #303133;
  line-height: 0.44rem;
  margin-top: 0.48rem;
}
.topmsg > :nth-child(3) {
  font-size: 0.28rem;
  font-weight: 500;
  color: #909399;
  line-height: 0.4rem;
  margin-top: 0.08rem;
  margin-bottom: 0.56rem;
}
.bcdyclass {
  font-size: 0.32rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;
  background: linear-gradient(90deg, #ff5f18 0%, #ff8d35 100%);
  border-radius: 0.48rem;
  text-align: center;
  padding: 0.24rem;
  margin: 0.28rem 0.24rem;
}
.fontnumthe {
  color: #c0c4cc;
  margin-left: 0.2rem;
}
.fontnumclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.28rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.25);
  line-height: 0.44rem;
}
.areaclass {
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
  margin: 0.2rem 0;
  background-color: #ffffff;
  padding: 0.32rem;
}
.areaclass textarea {
  width: 100%;
  margin-top: 0.32rem;
  border: none;
}
.thecenterclass {
  background-color: #ffffff;
  margin: 0.2rem 0;
}
.thecenterclass > :last-child {
  border: none;
}
.centerclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.32rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
  border-bottom: 1px solid #f6f6f7;
}
.centerclass > :first-child {
  white-space: nowrap;
  margin-right: 0.3rem;
}
input::input-placeholder,
textarea::input-placeholder {
  color: #c0c4cc;
  font-weight: 400;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #c0c4cc;
  font-weight: 400;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #c0c4cc;
  font-weight: 400;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #c0c4cc;
  font-weight: 400;
}
.gwclass {
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 0.32rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.4rem;
}
.gwclass > :first-child {
  margin-right: 0.2rem;
}
.gwclass > :nth-child(2) {
  flex: 1;
}
.gwclass input {
  border: none;
}
.xh {
  color: #ff1f1f;
  font-size: 0.3rem;
  margin-left: 0.05rem;
}
.allmsgclass >>> .van-popup--bottom.van-popup--round {
  border-radius: 0;
}
.hangyclass {
  display: flex;
  flex-wrap: wrap;
  padding: 0.3rem;
}
.sh1c {
  margin: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 0.42rem;
  background: #f7f7f7;
  border-radius: 0.08rem;
  padding: 0.21rem 0.24rem;
}
.selehangclass {
  margin: 0.2rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.42rem;
  background: #ffffff;
  border-radius: 0.08rem;
  padding: 0.18rem 0.21rem;
  color: #ff5f19;
  border: 0.03rem solid #ff5f19;
}
.rgc {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mcl2 {
  padding: 0.42rem 0;
  border-bottom-color: #d6d6d6;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 0.28rem;
}
.mcl2 input {
  border: none;
}
.mcl2 > :first-child {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #676767;
  line-height: 0.48rem;
}
.mcl2 > input {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 0.48rem;
  width: 100%;
}
.oncl {
  margin-top: 0.15rem;
}
.msgclass {
  margin: 0.38rem 0.4rem;
}
.allmsgclass {
  min-height: 100%;
}
.setding {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ff6d20;
  line-height: 0.48rem;
}
.setding > :first-child {
  margin-right: 0.16rem;
}
</style>
